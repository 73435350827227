<template>
    <div class="flex flex-wrap items-center">
        <vs-col class="sm:mt-0 mt-2" vs-type="flex" vs-justify="flex-end">
          <!-- 選開始日期 -->
          <el-date-picker
            v-model="selectedStartDate"
            type="date"
            :placeholder="$t('opinion.startDate')"
            value-format="yyyy-MM-dd">
          </el-date-picker>
          <!-- 選結束日期 -->
          <el-date-picker
            class="sm:mx-2 mx-1"
            v-model="selectedEndDate"
            type="date"
            :placeholder="$t('opinion.endDate')"
            value-format="yyyy-MM-dd">
          </el-date-picker>
          <!-- 搜尋按鈕 -->
          <vs-button class="px-5" type="flat" :size="windowWidth >= 768 ? '' :''" radius icon="search" :disable="selectedStartDate==null && selectedEndDate==null" @click="opiniondatafilter"></vs-button>
        </vs-col>
    </div>
</template>
<style>
  @media only screen and (max-width: 600px) {
    .el-date-range-picker .el-picker-panel__body {
      min-width: 350px;
    }
  }
  @media only screen and (max-width: 400px) {
    .el-date-range-picker .el-picker-panel__body {
      min-width: 350px;
    }
  }
</style>
<script>
export default {
  props: {
    OpinionFilter: { 
      type:String,
      required: true
    }
  },
  data() {
    return {
      selectedStartDate: null,
      selectedEndDate: null,
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    //搜尋會診資料
    opiniondatafilter(){
      var _self = this
      _self.$vs.loading()
      // 沒選日期
      if(_self.selectedStartDate==null && _self.selectedEndDate==null){
        _self.$store.commit('SET_OPFILTER_FLAG', false)
        // 被邀請
        if(_self.OpinionFilter==1){
          _self.$store.commit('SET_MYOPINION_START_DATE_TIME', null)
          _self.$store.commit('SET_MYOPINION_END_DATE_TIME', null)
          _self.$store.commit('SET_MYOPINION_PAGE', 1)
          _self.$store.dispatch('fetchMyOpinion', 1).then((res) => {
            _self.$store.commit('SET_MYOPINION_TOTAL', res.data.total)
            _self.$store.commit('SET_OPINION_ACTIVE_COUNT', res.data.opinion_status_total)
          }).catch((error) => { 
            console.log(error)
          }).then(() => {
            _self.$vs.loading.close()
          })
        }
        // 邀請
        else if(_self.OpinionFilter==2){
          _self.$store.commit('SET_OPINION_START_DATE_TIME', null)
          _self.$store.commit('SET_OPINION_END_DATE_TIME', null)
          _self.$store.commit('SET_OPINION_PAGE', 1)
          _self.$store.dispatch('fetchOpinion', 1).then((res) => {
            _self.$store.commit('SET_OPINION_TOTAL', res.data.total)
            _self.$store.commit('SET_OPINION_UNFINISH_TOTAL', res.data.opinion_status_total)
          }).catch((error) => { 
            console.log(error)
          }).then(() => {
            _self.$vs.loading.close()
          })
        }
      }
      // 有選日期
      else if(_self.selectedStartDate!=null && _self.selectedEndDate!=null){
        _self.$store.commit('SET_OPFILTER_FLAG', true)
        // 被邀請
        if(_self.OpinionFilter==1){
          _self.$store.commit('SET_MYOPINION_START_DATE_TIME', _self.selectedStartDate)
          _self.$store.commit('SET_MYOPINION_END_DATE_TIME', _self.selectedEndDate)
          _self.$store.commit('SET_MYOPINION_PAGE', 1)
          _self.$store.dispatch('dateSearchMyOpinion').then((res) => {
            _self.$vs.loading.close()
          }).catch((error) => { 
            console.log(error)
          })
        }
        // 邀請
        else if(_self.OpinionFilter==2){
          _self.$store.commit('SET_OPINION_START_DATE_TIME', _self.selectedStartDate)
          _self.$store.commit('SET_OPINION_END_DATE_TIME', _self.selectedEndDate)
          _self.$store.commit('SET_OPINION_PAGE', 1)
          _self.$store.dispatch('dateSearchOpinion').then((res) => {
            _self.$vs.loading.close()
          }).catch((error) => { 
            console.log(error)
          })
        }
      }
    }
  },
}
</script>